
import auth from "../services/auth";
import validate from "../services/validator";
import router from "./../router";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import { defineComponent, reactive, ref, UnwrapRef, onMounted } from "vue";
import { notification } from "ant-design-vue";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import authServices from "../services/auth";

interface FormState {
  email: string;
  password: string;
  remember: boolean;
  redirect: boolean;
  subdomain: string;
}
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const route = useRoute();
    const visible = ref<boolean>(false);
    const signInLogezy = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const fetchLoading = ref<boolean>(false);
    const addressLoading = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const formState: UnwrapRef<FormState> = reactive({
      email: "",
      password: "",
      remember: false,
      redirect: false,
      subdomain: ""
    });
    const rules = validate.loginValidation;
    const formRef = ref();
    const userTenants = ref([]);
    const forgotPasswordEmail = ref<string>("");
    const logezyEmail = ref<string>("");
    const logezyPassword = ref<string>("");
    const logezyAddress = ref();
    const logezyAddressId = ref();
    const hideSignup = ref();
    const emailError = ref<boolean>(false);
    const emailModalError = ref<boolean>(false);
    const showForgotPopup = () => {
      visible.value = true;
    };
    const showLogezySignInPopup = () => {
      signInLogezy.value = true;
    };
     const filterOption = (input: string, option: any) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const onlogezyAddressChanged = async () => {
      const filteredAddress = userTenants.value.filter((itm: any) => {
          return itm.id === logezyAddressId.value
        })
      logezyAddress.value = filteredAddress[0]
    }
    const login = async (values: FormState) => {
      if(route.query.redirect){
        values.redirect = true;
      }
      let slug = localStorage.getItem('subdomain');
      if(slug){
        values.subdomain = slug;
      }      
      const logezy_email = localStorage.getItem('logezy_email');
      if(values.redirect == true && values.email !== logezy_email) {
        emailError.value = true;
      } else {
        loading.value = true;
        try {
          emailError.value = false;
          const res = await auth.login(values);
          if (res.data.data.success) {
            localStorage.accessToken = res.data.data.token.access_token;
            localStorage.tokenType = res.data.data.token.token_type;
            localStorage.profile = JSON.stringify(res.data.data.user_details[0]);
            if(res.data.data.user_details[0].role_id !== 1){
              localStorage.available_credits = res.data.data.user_details[0].available_credits
              localStorage.permission = JSON.stringify(res.data.data.permissions)
            }
            if(res.data.data.third_party_redirect) {
              // const auth_code = res.data.data.redirect.auth_code;
              // const client_id = res.data.data.redirect.client_id;
              // const redirect_uri = res.data.data.redirect.redirect_uri;
              const redirect_uri = localStorage.getItem('redirect_uri');
              // const email = localStorage.getItem('logezy_email');
              const client_id = localStorage.getItem('client_id');
              const subdomain = localStorage.getItem('subdomain');
              var hyper;
              if(redirect_uri.indexOf('localhost') > -1) {
                hyper = 'http';
              } else {
                hyper = 'https';
              }
              if(res.data.data.subdomainError){
                logout();
                window.location.href = hyper + '://'+ redirect_uri + '?subdomain_error=true' ;
              } else {
                // console.log(res.data.data);
                if(logezy_email != res.data.data.user_details[0].email) {
                  logout();              
                  window.location.href = hyper + '://'+ redirect_uri +'?email_error=true';
                } else if(res.data.data.authcode){
                  window.location.href = hyper + '://'+ redirect_uri + '?code=' + res.data.data.authcode;
                } else {
                  router.push("/oauth/authorize?client_id="+ client_id + "&redirect_uri=" + redirect_uri+ '&response_type=code&scope=&email='+logezy_email+'&subdomain='+subdomain);
                } 
              }                  
              return
            }
            if(res.data.data.user_details[0].role_id !== 1){
              router.push("/dashboard");
            } else {
              router.push("/admin-agencies");
            }        
          }
          loading.value = false;
        } catch (error: any) {
          if (error.response) {
            // console.log("Error", error.response);
            await notify("Error", error.response.data.message, "error");
          }
          loading.value = false;
          localStorage.removeItem("accessToken");
        }
      }
      
    };
    const fetchTenants = async () => {

      logezyAddress.value = '';
      logezyAddressId.value = [];
      userTenants.value = [];
      if (!logezyEmail.value) {
        await notify("Error", "Email is required", "error");
        return;
      } else if (!logezyPassword.value) {
        await notify("Error", "password is required", "error");
        return;
      } else {
        const regexp = new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        const validateEmail = regexp.test(logezyEmail.value);
        if (validateEmail) {
          const logezy_email = localStorage.getItem('logezy_email');          
          if(route.query.redirect && logezyEmail.value !== logezy_email){
            emailModalError.value = true;
          } else {
            addressLoading.value = true
            try {
              emailModalError.value = false;
              const res = await auth.fetchtenants({
                email: logezyEmail.value,
                password: logezyPassword.value,
              });
              if (res.data.data.success) {
                userTenants.value = res.data.data.user_tenants;
              } 
              addressLoading.value = false
            } catch (error: any) {
              if (error.response) {
                console.log(error.response);
                await notify("Error", error.response.data.message, "error");
              }
              addressLoading.value = false
            }
          }
          
        } else {
          await notify("Error", "Enter a valid email", "error");
        }
      }
    };
    // const signinwithlogezy = async () => {
    //   fetchLoading.value = true;
    //   try {
    //     const res = await auth.signinwithlogezy(values);
    //     if (res.data.data.success) {
    //       localStorage.accessToken = res.data.data.token.access_token;
    //       localStorage.tokenType = res.data.data.token.token_type;
    //       localStorage.profile = JSON.stringify(res.data.data.user_details[0]);
    //       if(res.data.data.user_details[0].role_id !== 1){
    //         // localStorage.available_credits = res.data.data.credits.available_credits
    //         localStorage.available_credits = res.data.data.user_details[0].available_credits
    //         localStorage.permission = JSON.stringify(res.data.data.permissions)
    //         // localStorage.permission = JSON.stringify(
    //         //   res.data.data.userPermissions
    //         // );
    //         // if (res.data.data.userType) {
    //         //   localStorage.userType = JSON.stringify(res.data.data.userType);
    //         // }
    //         router.push("/dashboard");
    //       } else {
    //         router.push("/admin-agencies");
    //       }        
    //     }
    //     fetchLoading.value = false;
    //   } catch (error: any) {
    //     if (error.response) {
    //       console.log("Error", error.response);
    //       await notify("Error", error.response.data.message, "error");
    //     }
    //     fetchLoading.value = false;
    //     localStorage.removeItem("accessToken");
    //   }
    // };

    const signinwithlogezy = async () => {
      if (!logezyEmail.value) {
        await notify("Error", "Email is required", "error");
        return;
      } else if (!logezyPassword.value) {
        await notify("Error", "password is required", "error");
        return;
      } else if (!logezyAddress.value) {
        await notify("Error", "Address is required", "error");
        return;
      } else {
        const regexp = new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        const validateEmail = regexp.test(logezyEmail.value);
        if (validateEmail) {
          try {
            const subdomain = localStorage.getItem('subdomain');
            fetchLoading.value = true;
            const res = await auth.signinwithlogezy({
              email: logezyEmail.value,
              password: logezyPassword.value,
              tenant: logezyAddress.value,
              redirect: route.query.redirect,
              subdomain: subdomain
            });
            if (res.data.data.success) {
              localStorage.accessToken = res.data.data.token.access_token;
              localStorage.tokenType = res.data.data.token.token_type;
              localStorage.profile = JSON.stringify(res.data.data.user_details[0]);
              if(res.data.data.user_details[0].role_id !== 1){
                localStorage.available_credits = res.data.data.user_details[0].available_credits
                localStorage.permission = JSON.stringify(res.data.data.permissions)
              }
              if(res.data.data.third_party_redirect) {
                const redirect_uri = localStorage.getItem('redirect_uri');
                const email = localStorage.getItem('logezy_email');
                const client_id = localStorage.getItem('client_id');                
                var hyper;
                if(redirect_uri.indexOf('localhost') > -1) {
                  hyper = 'http';
                } else {
                  hyper = 'https';
                }
                if(email != res.data.data.user_details[0].email) {
                  logout();              
                  window.location.href = hyper + '://'+ redirect_uri +'?email_error=true';
                } else if(res.data.data.authcode){
                  window.location.href = hyper + '://'+ redirect_uri + '?code=' + res.data.data.authcode;
                } else {
                  router.push("/oauth/authorize?client_id="+ client_id + "&redirect_uri=" + redirect_uri+ '&response_type=code&scope=&email='+email+'&subdomain='+subdomain);
                }       
                return
              }
              if(res.data.data.user_details[0].role_id !== 1){
                router.push("/dashboard");
              } else {
                router.push("/admin-agencies");
              }        
            }
          } catch (error: any) {
            if (error.response) {
              console.log("Error", error.response);
              await notify("Error", error.response.data.message, "error");
            }
            fetchLoading.value = false;
            localStorage.removeItem("accessToken");
          }
        } else {
          await notify("Error", "Enter a valid email", "error");
        }
      }
    };
    const handleFinishFailed = (errors: ValidateErrorEntity<FormState>) => {
      console.log("errors", errors);
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const sendPassword = async () => {
      if (!forgotPasswordEmail.value) {
        await notify("Error", "Email is required to request password", "error");
        return;
      } else {
        const regexp = new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        const validateEmail = regexp.test(forgotPasswordEmail.value);
        if (validateEmail) {
          try {
            confirmLoading.value = true;
            const res = await auth.forgotPassword({
              email: forgotPasswordEmail.value,
            });
            if (res.data.data.success) {
              confirmLoading.value = false;
              await notify(
                "Success",
                "New password has been send to your registered email.",
                "success"
              );
              forgotPasswordEmail.value = "";
              visible.value = false;
            } else {
              confirmLoading.value = false;
            }
          } catch (error: any) {
            if (error.response) {
              console.log("pass", error.response);
              confirmLoading.value = false;
              await notify("Error", error.response.data.data.message, "error");
            }
          }
        } else {
          await notify("Error", "Enter a valid email", "error");
        }
      }
    };
    const handleCancel = () => {
      visible.value = false;
      signInLogezy.value = false;
      confirmLoading.value = false;
    };
    const signUp = () => {
      router.push(`/signup`);
    };
    const logout = async () => {
       try {
        const res = await authServices.logout();
        if (res.data.message === 'Successfully logged out') {
          delete localStorage.accessToken
          delete localStorage.profile
          localStorage.clear()
        }
      } catch (error: any) {
        console.log(error);
      }
    };
    onMounted(async () => {
      if(!route.query.redirect){
        hideSignup.value = true;
      } else {
        hideSignup.value = false;
      }
    });
    return {
      visible,
      signInLogezy,
      formState,
      logezyEmail,
      logezyPassword,
      logezyAddress,
      logezyAddressId,
      userTenants,
      formRef,
      handleFinishFailed,
      login,
      fetchTenants,
      signinwithlogezy,
      resetForm,
      loading,
      fetchLoading,
      addressLoading,
      rules,
      notify,
      forgotPasswordEmail,
      showForgotPopup,
      showLogezySignInPopup,
      handleCancel,
      sendPassword,
      confirmLoading,
      signUp,
      filterOption,
      onlogezyAddressChanged,
      hideSignup,
      emailError,
      emailModalError
    };
  },
});
